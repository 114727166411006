import { FeeInfo } from "./";

export enum LivesWith {
  parents = 'Padres',
  brothers = 'Hermanos',
  children = 'Hijos',
  couple = 'Pareja estable',
  husband_or_wife = 'Espos@',
  alone = 'Solo',
  friends = 'Amigos',
  other = 'Otros'
}

export enum EducationLevel {
  none = 'Ninguno',
  high_school = 'Bachiller',
  undergraduate = 'Pregrado',
  postgraduate = 'Postgrado'
}

export enum VehicleType {
  none = 'Ninguno',
  motorcycle = 'Moto',
  car = 'Carro'
}

export enum Occupation {
  unemployed = 'Desempleado',
  student = 'Estudiante',
  freelance = 'Independiente',
  employee = 'Empleado',
  housewive = 'Amo de casa',
  retired = 'Pensionado'
}

interface ILoanApplication {
  amount: number;
  days: number;
  amountToPay: number;
  annualEffectiveInterestRate: number;
  chargedInterest: number;
  fees?: FeeInfo[];
  state: string;
  city: string;
  region: string;
  address: string;
  layer: number;
  yearsInAddress: number;
  livesWith: LivesWith;
  peopleInCharge: number;
  children: number;
  monthlySalary: number;
  totalMonthlyIncome: number;
  educationLevel: EducationLevel;
  vehicleType: VehicleType;
  vehiclePlates?: string;
  occupation: Occupation;
  company?: string;
  companyPhone?: string;
  personalReferenceName: string;
  personalReferencePhone: string;
  personalReferenceEmail: string;
  personalReferenceRelation: string;
  familyReferenceName: string;
  familyReferencePhone: string;
  familyReferenceEmail: string;
  familyReferenceRelation: string;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
}

export default class LoanApplication {
  amount: number;
  days: number;
  amountToPay: number;
  annualEffectiveInterestRate: number;
  chargedInterest: number;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
  fees: FeeInfo[];
  state: string;
  city: string;
  region: string;
  address: string;
  layer: number;
  yearsInAddress: number;
  livesWith: LivesWith;
  peopleInCharge: number;
  children: number;
  monthlySalary: number;
  totalMonthlyIncome: number;
  educationLevel: EducationLevel;
  vehicleType: VehicleType;
  vehiclePlates?: string;
  occupation: Occupation;
  company?: string;
  companyPhone?: string;
  personalReferenceName: string;
  personalReferencePhone: string;
  personalReferenceEmail: string;
  personalReferenceRelation: string;
  familyReferenceName: string;
  familyReferencePhone: string;
  familyReferenceEmail: string;
  familyReferenceRelation: string;

  constructor({
    amount,
    days,
    amountToPay,
    annualEffectiveInterestRate,
    chargedInterest,
    chargedMaintenanceFee,
    chargedIvaFee,
    fees = [],
    state,
    city,
    region,
    address,
    layer,
    yearsInAddress,
    livesWith,
    peopleInCharge,
    children,
    monthlySalary,
    totalMonthlyIncome,
    educationLevel,
    vehicleType,
    vehiclePlates,
    occupation,
    company,
    companyPhone,
    personalReferenceName,
    personalReferencePhone,
    personalReferenceEmail,
    personalReferenceRelation,
    familyReferenceName,
    familyReferencePhone,
    familyReferenceEmail,
    familyReferenceRelation
  }: ILoanApplication) {
    this.amount = amount;
    this.days = days;
    this.amountToPay = amountToPay;
    this.annualEffectiveInterestRate = annualEffectiveInterestRate;
    this.chargedInterest = chargedInterest;
    this.chargedMaintenanceFee = chargedMaintenanceFee;
    this.chargedIvaFee = chargedIvaFee;
    this.fees = fees;
    this.state = state;
    this.city = city;
    this.region = region;
    this.address = address;
    this.layer = layer;
    this.yearsInAddress = yearsInAddress;
    this.livesWith = livesWith;
    this.peopleInCharge = peopleInCharge;
    this.children = children;
    this.monthlySalary = monthlySalary;
    this.totalMonthlyIncome = totalMonthlyIncome;
    this.educationLevel = educationLevel;
    this.vehicleType = vehicleType;
    this.vehiclePlates = vehiclePlates;
    this.occupation = occupation;
    this.company = company;
    this.companyPhone = companyPhone;
    this.personalReferenceName = personalReferenceName;
    this.personalReferencePhone = personalReferencePhone;
    this.personalReferenceEmail = personalReferenceEmail;
    this.personalReferenceRelation = personalReferenceRelation;
    this.familyReferenceName = familyReferenceName;
    this.familyReferencePhone = familyReferencePhone;
    this.familyReferenceEmail = familyReferenceEmail;
    this.familyReferenceRelation = familyReferenceRelation;
  }
  
  static enumToLivesWithCd = (livesWith: LivesWith): number => {
    switch (livesWith) {
      case LivesWith.parents:
        return 0;
      case LivesWith.brothers:
        return 1;
      case LivesWith.children:
        return 2;
      case LivesWith.couple:
        return 3;
      case LivesWith.husband_or_wife:
        return 4;
      case LivesWith.alone:
        return 5;
      case LivesWith.friends:
        return 6;
      case LivesWith.other:
        return 7;
      default:
        throw new Error(`Unknown value: ${livesWith}`);
    }
  }

  static enumToEducationLevelCd = (educationLevel: EducationLevel): number => {
    switch (educationLevel) {
      case EducationLevel.none:
        return 0;
      case EducationLevel.high_school:
        return 1;
      case EducationLevel.undergraduate:
        return 2;
      case EducationLevel.postgraduate:
        return 3;
      default:
        throw new Error(`Unknown value: ${educationLevel}`);
    }
  }

  static enumToVehicleTypeCd = (vehicleType: VehicleType): number => {
    switch (vehicleType) {
      case VehicleType.none:
        return 0;
      case VehicleType.motorcycle:
        return 1;
      case VehicleType.car:
        return 2;
      default:
        throw new Error(`Unknown value: ${vehicleType}`);
    }
  }

  static enumToOccupationCd = (occupation: Occupation): number => {
    switch (occupation) {
      case Occupation.unemployed:
        return 0;
      case Occupation.student:
        return 1;
      case Occupation.freelance:
        return 2;
      case Occupation.employee:
        return 3;
      case Occupation.housewive:
        return 4;
      case Occupation.retired:
        return 5;
      default:
        throw new Error(`Unknown value: ${occupation}`);
    }
  }
}
