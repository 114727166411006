import { ProjectedPayment } from "../../../../domain/models";
import { ProjectedPaymentEntity } from "../entities";

export default class ProjectedPaymentMapper {
  static toModel(entity: ProjectedPaymentEntity): ProjectedPayment {
    return new ProjectedPayment({
      chargedInterest: entity.charged_interest,
      chargedMaintenanceFee: entity.charged_maintenance_fee,
      chargedIvaFee: entity.charged_iva_fee,
      days: entity.days,
      amountToPay: entity.amount_to_pay,
      fees: entity.fees
    });
  }
}