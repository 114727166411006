interface DocumentTypes {
  [key: string]: number;
}

interface GenderTypes {
  [key: string]: number;
}

interface CivilStatusTypes {
  [key: string]: number;
}

interface BankAccountType {
  [key: string]: number;
}

interface CreditYearsOfLivingInResidency {
  [key: string]: number;
}

interface EducationLevel {
  [key: string]: number;
}

interface HomeTenant {
  [key: string]: number;
}

interface VehicleType {
  [key: string]: number;
}

interface Occupation {
  [key: string]: number;
}

interface IncomeRange {
  [key: string]: number;
}

interface CreditScore {
  [key: string]: number;
}

interface PaymentMethod {
  [key: string]: number;
}

interface ModelPredictionResult {
  [key: string]: number;
}

interface EmailType {
  [key: string]: string;
}

export default class Constants {
  static NON_MATCHING_PASSWORDS_ERROR_MESSAGE = 'Las contraseñas no coinciden';
  static UPDATED_PASSWORD_SUCCESSFULLY = 'Contraseña actualizada exitosamente';
  static UPDATED_PERSONAL_INFORMATION_SUCCESSFULLY = 'Datos actualizados exitosamente';
  static DOCUMENT_TYPES: DocumentTypes = {
    'Cedula de ciudadania': 0,
    'Cedula de extranjeria': 1,
  };
  static GENDER_TYPES: GenderTypes = {
    'Masculino': 0,
    'Femenino': 1,
  };
  static CIVIL_STATUS_TYPES: CivilStatusTypes = {
    'Soltero': 0,
    'Casado': 1,
    'Divorciado': 3,
    'Viudo': 4,
    'Separado': 5,
    'Union libre': 6,
  };
  static BANK_ACCOUNT_TYPES: BankAccountType = {
    'Ahorros': 0,
    'Corriente': 1,
  };
  static CREDIT_YEARS_OF_LIVING_IN_RESIDENCY: CreditYearsOfLivingInResidency = {
    "Menos de un año": 0,
    "1 año": 1,
    "2 años": 2,
    "3 años": 3,
    "4 años": 4,
    "Mas de 4 años": 5
  }
  static EDUCATION_LEVELS: EducationLevel = {
    'Ninguno': 0,
    'Bachiller': 1,
    'Pregrado': 2,
    'Postgrado': 3,
  }
  static HOME_TENANTS: HomeTenant = {
    'Padres': 0,
    'Hermanos': 1,
    'Hijos': 2,
    'Pareja estable': 3,
    'Espos@': 4,
    'Solo': 5,
    'Amigos': 6,
    'Otros':  7
  }
  static VEHICLE_TYPES: VehicleType = {
    'Ninguno': 0,
    'Moto': 1,
    'Carro': 2
  }
  static OCCUPATIONS: Occupation = {
    'Desempleado': 0,
    'Estudiante': 1,
    'Independiente': 2,
    'Empleado': 3,
    'Ama de casa': 4,
    'Pensionado': 5
  }
  static CREDIT_SCORES: CreditScore = {
    'Sin historial / No califica': 0,
    'Bajo - A': 1,
    'Medio - AA': 2,
    'Alto - AAA': 3
  }
  static PAYMENT_METHODS: PaymentMethod = {
    'Transferencia': 0,
    'En línea': 1,
  }
  static LAYERS = [1,2,3,4,5,6]
  static PEOPLE_IN_CHARGE = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  static NUMBER_OF_CHILDREN = [0, 1, 2, 3, 4, 5]
  static INCOME_RANGES: IncomeRange = {
    'Menos de 1 millón de pesos colombianos': 1000000,
    'Entre 1 millón a 2 millones de pesos colombianos': 2000000,
    'Entre 2 millones a 3 millones de pesos colombianos': 3000000,
    'Entre 5 millones a 10 millones de pesos colombianos': 5000000,
    'Entre 10 millones a 20 millones de pesos colombianos': 10000000,
    'Más de 20 millones de pesos colombianos': 20000000,
  }

  static CREDIT_TERM_STEP: number = 5;
  static CREDIT_AMOUNT_STEP: number = 50000;
  static UPLOADED_SUPPORT_FILE_CORRECTLY_MSG: string = 'El archivo de soporte se subió correctamente';
  static UPDATED_EMAIL_CORRECTLY_MSG: string = 'El email se actualizó correctamente';
  static UPDATED_TEMPLATE_CORRECTLY_MSG: string = 'El template se actualizó correctamente';
  static GENERIC_ERROR_MSSG = 'Oops! Algo salió mal, por favor intenta de nuevo';
  static IVA_FEE_CODE = 'iva_fee';
  static MAINTENANCE_FEE_CODE = 'maintenance_fee';
  static MODEL_PREDICTION_RESULTS: ModelPredictionResult = {
    'user_pays': 1,
    'user_does_not_pay': 0,
    'error': -1
  }
  static EMAIL_TYPES: EmailType = {
    'event': 'event',
    'loan_status_change': 'loan_status_change'
  };
}