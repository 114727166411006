import { FeeInfo } from "./";

interface IProjectedPayment {
  chargedInterest: number;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
  days: number;
  amountToPay: number;
  fees: FeeInfo[];
}

export default class ProjectedPayment {
  chargedInterest: number;
  chargedMaintenanceFee: number;
  chargedIvaFee: number;
  days: number;
  amountToPay: number;
  fees: FeeInfo[];

  constructor({
    chargedInterest, days, amountToPay, fees,
    chargedMaintenanceFee, chargedIvaFee
  }: IProjectedPayment) {
    this.chargedInterest = chargedInterest;
    this.chargedMaintenanceFee = chargedMaintenanceFee;
    this.chargedIvaFee = chargedIvaFee;
    this.days = days;
    this.amountToPay = amountToPay;
    this.fees = fees;
  }
}