import { LoanApplication } from "../../../../domain/models";
import { EducationLevel, LivesWith, Occupation, VehicleType } from "../../../../domain/models/loan_application";
import { LoanApplicationEntity } from "../entities";

export default class LoanApplicationMapper {
  static toModel(entity: LoanApplicationEntity): LoanApplication {
    return new LoanApplication({
      amount: entity.amount,
      days: entity.days,
      amountToPay: entity.amount_to_pay,
      chargedInterest: entity.charged_interest,
      chargedMaintenanceFee: entity.charged_maintenance_fee,
      chargedIvaFee: entity.charged_iva_fee,
      annualEffectiveInterestRate: entity.annual_effective_interest_rate,
      fees: entity.fees,
      state: entity.state,
      city: entity.city,
      region: entity.region,
      address: entity.address,
      layer: entity.layer,
      yearsInAddress: entity.years_in_address,
      livesWith: this.livesWithCdToEnum(entity.lives_with_cd),
      peopleInCharge: entity.people_in_charge,
      children: entity.children,
      monthlySalary: entity.monthly_salary,
      totalMonthlyIncome: entity.total_monthly_income,
      educationLevel: this.educationLevelCdToEnum(entity.education_level_cd),
      vehicleType: this.vehicleTypeCdToEnum(entity.vehicle_type_cd),
      vehiclePlates: entity.vehicle_plates,
      occupation: this.occupationCdToEnum(entity.occupation_cd),
      company: entity.company,
      companyPhone: entity.company_phone,
      personalReferenceName: entity.personal_reference_name,
      personalReferencePhone: entity.personal_reference_phone,
      personalReferenceEmail: entity.personal_reference_email,
      personalReferenceRelation: entity.personal_reference_relation,
      familyReferenceName: entity.family_reference_name,
      familyReferencePhone: entity.family_reference_phone,
      familyReferenceEmail: entity.family_reference_email,
      familyReferenceRelation: entity.family_reference_relation
    })
  }

  static livesWithCdToEnum = (livesWithCd: number): LivesWith => {
    switch (livesWithCd) {
      case 0:
        return LivesWith.parents;
      case 1:
        return LivesWith.brothers;
      case 2:
        return LivesWith.children;
      case 3:
        return LivesWith.couple;
      case 4:
        return LivesWith.husband_or_wife;
      case 5:
        return LivesWith.alone;
      case 6:
        return LivesWith.friends;
      case 7:
        return LivesWith.other;
      default:
        throw new Error(`Unknown value: ${livesWithCd}`);
    }
  }

  static educationLevelCdToEnum = (educationLevelCd: number): EducationLevel => {
    switch (educationLevelCd) {
      case 0:
        return EducationLevel.none;
      case 1:
        return EducationLevel.high_school;
      case 2:
        return EducationLevel.undergraduate;
      case 3:
        return EducationLevel.postgraduate;
      default:
        throw new Error(`Unknown value: ${educationLevelCd}`);
    }
  }

  static vehicleTypeCdToEnum = (vehicleTypeCd: number): VehicleType => {
    switch (vehicleTypeCd) {
      case 0:
        return VehicleType.none;
      case 1:
        return VehicleType.motorcycle;
      case 2:
        return VehicleType.car;
      default:
        throw new Error(`Unknown value: ${vehicleTypeCd}`);
    }
  }

  static occupationCdToEnum = (occupationCd: number): Occupation => {
    switch (occupationCd) {
      case 0:
        return Occupation.unemployed;
      case 1:
        return Occupation.student;
      case 2:
        return Occupation.freelance;
      case 3:
        return Occupation.employee;
      case 4:
        return Occupation.housewive;
      case 5:
        return Occupation.retired;
      default:
        throw new Error(`Unknown value: ${occupationCd}`);
    }
  }
}