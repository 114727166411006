interface IConfig {
  minAmount: number;
  maxAmount: number;
  minDays: number;
  maxDays: number;
  defaultAnnualEffectiveInterestRate: number;
  onlinePaymentFeeRate: number;
  dashboardUrl: string;
  dashboardPassword: string;
  contactEmail: string;
  contactPhone: string;
  contactAddress: string;
  onlineSignatureFee: number;
  creditScoreConsultationFee: number;
  maintenanceFee: number;
}

export default class Config {
  minAmount: number;
  maxAmount: number;
  minDays: number;
  maxDays: number;
  defaultAnnualEffectiveInterestRate: number;
  onlinePaymentFeeRate: number;
  dashboardUrl: string;
  dashboardPassword: string;
  contactEmail: string;
  contactPhone: string;
  contactAddress: string;
  onlineSignatureFee: number;
  creditScoreConsultationFee: number;
  maintenanceFee: number;

  constructor({
    minAmount, maxAmount, minDays, maxDays,
    defaultAnnualEffectiveInterestRate, onlinePaymentFeeRate, dashboardUrl,
    dashboardPassword, contactEmail, contactPhone,
    contactAddress, onlineSignatureFee, creditScoreConsultationFee,
    maintenanceFee
  }: IConfig) {
    this.minAmount = minAmount;
    this.maxAmount = maxAmount;
    this.minDays = minDays;
    this.maxDays = maxDays;
    this.defaultAnnualEffectiveInterestRate = defaultAnnualEffectiveInterestRate;
    this.onlinePaymentFeeRate = onlinePaymentFeeRate;
    this.dashboardUrl = dashboardUrl;
    this.dashboardPassword = dashboardPassword;
    this.contactEmail = contactEmail;
    this.contactPhone = contactPhone;
    this.contactAddress = contactAddress;
    this.onlineSignatureFee = onlineSignatureFee;
    this.creditScoreConsultationFee = creditScoreConsultationFee;
    this.maintenanceFee = maintenanceFee;
  }
}